import {companyPermissions} from "@/mixins/companyPermissions";
import InventoryService from "@/services/inventory.service";

const service = new InventoryService();

export default {
  name: "InventoryBoard",

  props: {
    company: {type: String, default: null},
    agent: {type: String, default: null},
    dateRange: {type: String, default: 'ALL_TIME'},
    styleType: {type: String, default: "row"},
  },

  data() {
    return {
      active: 0,
      archived: 0,
      sold: 0,
      noImage: 0,
      usedCars: 0,
      newCars: 0,
      isLoading: true,
    }
  },

  async mounted() {
    await this.count();
  },

  methods: {
    async count() {
      const res = await service.count(this.dateRange, this.agent);
      if (res && !res.error) {
        this.isLoading = false;
        this.active = res.data.active;
        this.archived = res.data.archived;
        this.sold = res.data.sold;
        this.noImage = res.data.noImage;
        this.usedCars = res.data.used;
        this.newCars = res.data.new;
      }
    },
  },

  watch: {
    dateRange: {
      async handler() {
        await this.count();
      }
    },
    agent: {
      async handler() {
        await this.count();
      }
    }
  },

  mixins: [companyPermissions],
};